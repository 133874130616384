import { createSlice } from '@reduxjs/toolkit';

import { StatusEnum } from 'utils/const';
import { IUser, IRejectLoginData } from './types';
import { thunks } from './thunks';
import { selectors } from './selectors';

interface AuthState {
  token: string | null;
  roles: string[];
  user: IUser | null;
  notFondUserError: IRejectLoginData | null;
  loginStatus: StatusEnum;
  logoutStatus: StatusEnum;
}

const initialState: AuthState = {
  token: null,
  roles: [],
  user: null,
  notFondUserError: null,
  loginStatus: StatusEnum.IDLE,
  logoutStatus: StatusEnum.IDLE,
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    UNAUTHORIZED: () => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.login.pending, (state) => {
        state.loginStatus = StatusEnum.PENDING;
      })
      .addCase(thunks.login.fulfilled, (state, { payload }) => {
        if (payload) {
          const { token, user } = payload;
          // TODO: take roles from the back when it's done
          state.roles.push(user.role);
          state.token = token;
          state.user = { ...state.user, ...user };
        }
        if (state.notFondUserError) {
          state.notFondUserError = initialState.notFondUserError;
        }
        state.loginStatus = StatusEnum.SUCCESS;
      })
      .addCase(thunks.login.rejected, (state, { payload }) => {
        if (payload) {
          state.notFondUserError = payload;
        }
        state.loginStatus = StatusEnum.FAIL;
      })

      .addCase(thunks.logout.pending, (state) => {
        state.logoutStatus = StatusEnum.PENDING;
      })
      .addCase(thunks.logout.fulfilled, (state) => {
        state.logoutStatus = StatusEnum.SUCCESS;
      })
      .addCase(thunks.logout.rejected, (state) => {
        state.logoutStatus = StatusEnum.FAIL;
      });
  },
});

const auth = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { auth };
export default slice.reducer;
