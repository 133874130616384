import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';
import { IBalance, ITransParam, ResDataTransaction } from './types';

const getBalance = createAsyncThunk('tiptops/getBalance', async () => {
  try {
    const { data } = await api.user.tipTopsModule.getBalance();
    return data.data as IBalance[];
  } catch (err) {
    if (axios.isAxiosError(err)) {
      handleServerErrors(err);
    }
    throw err;
  }
});

const getTransactions = createAsyncThunk<ResDataTransaction, ITransParam>(
  'tiptops/getTransactions',
  async (params) => {
    try {
      const data = await api.user.tipTopsModule.getTransactions(params);
      return data.data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleServerErrors(err);
      }
      throw err;
    }
  }
);

export const thunks = {
  getBalance,
  getTransactions,
};
