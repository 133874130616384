import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';

const reportDetails = {
  getReportDetails(reportId: string) {
    return http.get(
      WEB_API_ROUTES.user.reportDetails.getReportDetails.replace(
        '{report}',
        reportId
      )
    );
  },
};

export { reportDetails };
