import { RootState } from 'store';

export const selectors = {
  getBalanceStatus: (state: RootState) => state.tiptops.getBalanceStatus,
  getTransStatus: (state: RootState) => state.tiptops.getTransactionsStatus,
  balance: (state: RootState) => state.tiptops.balance,
  meta: (state: RootState) => state.tiptops.pagination,
  received: (state: RootState) => state.tiptops.received,
  sent: (state: RootState) => state.tiptops.sent,
};
