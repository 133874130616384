import { createSlice } from '@reduxjs/toolkit';

import { StatusEnum } from 'utils/const';
import { IPaginate } from 'types/common';
import { thunks } from './thunks';
import { selectors } from './selectors';
import { ISearchUser, ITransaction } from './types';

interface IStore {
  metaTransaction: IPaginate;
  transactions: ITransaction[];
  userSearch: ISearchUser[];
  statusTransactions: StatusEnum;
  statusSearchUser: StatusEnum;
}

const initialState: IStore = {
  transactions: [],
  metaTransaction: {},
  userSearch: [],
  statusTransactions: StatusEnum.IDLE,
  statusSearchUser: StatusEnum.IDLE,
};

const slice = createSlice({
  name: 'adminTransaction',
  initialState,
  reducers: {
    CLEAR_USERS: (state) => {
      state.userSearch = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getTransactions.pending, (state) => {
        state.statusTransactions = StatusEnum.PENDING;
      })
      .addCase(thunks.getTransactions.fulfilled, (state, { payload }) => {
        const { current_page, per_page, total } = payload.meta;
        state.metaTransaction = {
          perPage: per_page,
          current: current_page,
          total,
        };

        state.transactions = payload.data;
        state.statusTransactions = StatusEnum.SUCCESS;
      })
      .addCase(thunks.getTransactions.rejected, (state) => {
        state.statusTransactions = StatusEnum.FAIL;
      })

      .addCase(thunks.searchUsers.pending, (state) => {
        state.statusSearchUser = StatusEnum.PENDING;
      })
      .addCase(thunks.searchUsers.fulfilled, (state, { payload }) => {
        state.userSearch = payload;
        state.statusSearchUser = StatusEnum.SUCCESS;
      })
      .addCase(thunks.searchUsers.rejected, (state) => {
        state.statusSearchUser = StatusEnum.FAIL;
      });
  },
});

const adminTransaction = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { adminTransaction };
export default slice.reducer;
