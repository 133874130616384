import { auth } from 'api/modules/auth';
import { createReport } from 'api/modules/user/createReport';
import { reportHistory } from 'api/modules/user/reportHistory';
import { additional } from 'api/modules/additional';
import { reportDetails } from 'api/modules/user/reportDetails';
import { workers } from 'api/modules/admin/workers';
import { tipTopsModule } from 'api/modules/user/tiptops';
import { topShopModule } from 'api/modules/user/topShop';
import { shop } from 'api/modules/admin/shop';
import { additional as adminAdditional } from 'api/modules/admin/additional';
import { transaction as adminTransaction } from 'api/modules/admin/transaction';

export const api = {
  auth,
  additional,
  user: {
    createReport,
    reportHistory,
    reportDetails,
    tipTopsModule,
    topShopModule,
  },
  admin: {
    workers,
    shop,
    adminAdditional,
    adminTransaction,
  },
};
