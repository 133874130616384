import { RoleType } from 'store/auth/types';
import { IProduct } from 'store/admin/shop/product/types';
import { IUserInfo } from 'store/admin/workers/types';

interface IWallet {
  id: number;
  balance: number;
  isPersonal: number;
}

interface IWalletCustom {
  personal: IWallet;
  send: IWallet;
}

enum OrderStatusEnum {
  NEW = 0,
  COMPLETED = 1,
  CANCELED = 2,
}

interface Order {
  id: number;
  status: OrderStatusEnum;
  amount: number;
  product: IProduct;
}

interface IUserOrder<T> {
  deletedAt: null | Date;
  email: string;
  firstName: string;
  fullName: string;
  id: number;
  lastName: string;
  orders: Order[];
  position: string;
  role: RoleType;
  timedoctorId: null | string;
  userInfo: IUserInfo;
  wallets: T extends 'customWallet' ? IWalletCustom : IWallet[];
}

export type { IProduct, IUserOrder, IWallet, Order, IWalletCustom };
export { OrderStatusEnum };
