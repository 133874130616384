import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';
import { IUserOrder } from './types';

const getOrderById = createAsyncThunk(
  'shop/order/getOrderById',
  async (id: string) => {
    try {
      const { data } = await api.admin.shop.getOrderById({ orderId: id });
      return data.data as IUserOrder<'serverWallet'>;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleServerErrors(err);
      }
      throw err;
    }
  }
);

export const thunks = {
  getOrderById,
};
