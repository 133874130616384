import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';
import { IDataTransaction, ISearchUser } from './types';

const getTransactions = createAsyncThunk(
  'admin/getTransaction',
  async (params: Record<string, any>) => {
    try {
      const { data } = await api.admin.adminTransaction.getTransactions(params);
      return data as IDataTransaction;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleServerErrors(err);
      }
      throw err;
    }
  }
);

const searchUsers = createAsyncThunk(
  'transaction/searchUsers',
  async (search: string) => {
    try {
      const { data } = await api.admin.adminTransaction.searchUsers(search);
      return data.data as ISearchUser[];
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleServerErrors(err);
      }
      throw err;
    }
  }
);

export const thunks = { getTransactions, searchUsers };
