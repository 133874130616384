import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format } from 'date-fns';

import { otherProjectModel, projectModel } from 'models/projectModel';
import {
  getPrevMonth,
  getSumOfTime,
  transformHoursStrToMinutes,
  transformMinutesToHoursStr,
} from 'utils/prepareDate';
import { projectsIds, StatusEnum } from 'utils/const';
import { IOtherProjectCustom, IProjectCustom } from 'types/project';
import { ITaskSubRow } from 'types/table';
import { periodFormat } from 'utils/formats';
import { thunks } from './thunks';
import { selectors } from './selectors';
import { IReport } from './types';

interface CreateReportState {
  currentReport: Omit<IReport, 'projects' | 'otherProjects'> | null;
  projects: IProjectCustom[];
  otherProjects: IOtherProjectCustom[];
  period: string;
  totalWorkedInMinutes: number;
  workingHours: number;
  isSaved: boolean;
  fetchingWorkingHoursStatus: StatusEnum;
  fetchingDataFromTimeDoctorStatus: StatusEnum;
  saveReportStatus: StatusEnum;
  fetchingLatestReportStatus: StatusEnum;
  sendReportStatus: StatusEnum;
  editReportStatus: StatusEnum;
  deleteReportStatus: StatusEnum;
}

const statuses = {
  fetchingWorkingHoursStatus: StatusEnum.IDLE,
  fetchingDataFromTimeDoctorStatus: StatusEnum.IDLE,
  saveReportStatus: StatusEnum.IDLE,
  fetchingLatestReportStatus: StatusEnum.IDLE,
  sendReportStatus: StatusEnum.IDLE,
  editReportStatus: StatusEnum.IDLE,
  deleteReportStatus: StatusEnum.IDLE,
} as const;

const initialState: CreateReportState = {
  currentReport: null,
  projects: [],
  otherProjects: [],
  period: format(getPrevMonth(new Date()), periodFormat),
  totalWorkedInMinutes: 0,
  workingHours: 0,
  isSaved: false,
  ...statuses,
};

const slice = createSlice({
  name: 'createReport',
  initialState,
  reducers: {
    RESET_STATE: (state) => {
      Object.assign(state, {
        ...initialState,
        workingHours: state.workingHours,
      });
    },

    RESET_STATUS: (
      state,
      { payload }: PayloadAction<keyof typeof statuses>
    ) => {
      state[payload] = StatusEnum.IDLE;
    },

    SET_TOTAL_WORKED_IN_MINUTES: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.totalWorkedInMinutes = payload;
    },

    SET_PERIOD: (state, { payload }: PayloadAction<string>) => {
      state.period = payload;
    },

    SET_IS_SAVED: (state, { payload }: PayloadAction<boolean>) => {
      state.isSaved = payload;
    },

    SET_TASK_TIME: (state, { payload }: PayloadAction<ITaskSubRow>) => {
      const { id, projectId, time, name } = payload;
      if (id && projectId) {
        const currentProjectIdx = state.projects.findIndex(
          (project) => project.id === projectId
        );

        if (currentProjectIdx !== -1) {
          const currentTaskIdx = state.projects[
            currentProjectIdx
          ].tasks.findIndex((task) => task.id === id);

          if (currentTaskIdx !== -1) {
            state.projects[currentProjectIdx].tasks[currentTaskIdx].time = time;

            const newTimeInMinutes = getSumOfTime(
              state.projects[currentProjectIdx].tasks
            );

            state.projects[currentProjectIdx].time =
              transformMinutesToHoursStr(newTimeInMinutes);
          }
        }
      } else {
        const currentOtherProjectIdx = state.otherProjects.findIndex(
          (other) => other.name === name
        );
        if (currentOtherProjectIdx !== -1) {
          state.otherProjects[currentOtherProjectIdx].time = time;
        }
      }
    },

    ADD_OTHER_PROJECT: (
      state,
      { payload }: PayloadAction<IOtherProjectCustom>
    ) => {
      const currentProject = state.otherProjects.findIndex(
        (project) => project.name === payload.name
      );
      if (currentProject !== -1) {
        const currentProjectTime = transformHoursStrToMinutes(
          state.otherProjects[currentProject].time
        );
        const payloadProjectTime = transformHoursStrToMinutes(payload.time);

        state.otherProjects[currentProject] = {
          ...payload,
          time: transformMinutesToHoursStr(
            currentProjectTime + payloadProjectTime
          ),
        };
        return;
      }

      state.otherProjects = [...state.otherProjects, payload];
    },

    DELETE_PROJECT: (state, { payload }: PayloadAction<number | string>) => {
      if (payload === projectsIds.ANOTHER) {
        state.otherProjects = initialState.otherProjects;
      } else {
        const idxProjectToRemove = state.projects.findIndex(
          ({ id }) => id === payload
        );
        if (idxProjectToRemove !== -1) {
          state.projects = [
            ...state.projects.slice(0, idxProjectToRemove),
            ...state.projects.slice(idxProjectToRemove + 1),
          ];
        }
      }
    },

    DELETE_PROJECT_TASK: (state, { payload }: PayloadAction<ITaskSubRow>) => {
      const { id, projectId, name } = payload;
      if (id && projectId) {
        const currentProjectIdx = state.projects.findIndex(
          (project) => project.id === projectId
        );
        const currentTaskIdx = state.projects[
          currentProjectIdx
        ].tasks.findIndex((task) => task.id === id);

        if (state.projects[currentProjectIdx].tasks.length === 1) {
          slice.caseReducers.DELETE_PROJECT(state, {
            payload: projectId,
            type: 'createReport/DELETE_PROJECT',
          });
        } else {
          state.projects[currentProjectIdx].tasks = [
            ...state.projects[currentProjectIdx].tasks.slice(0, currentTaskIdx),
            ...state.projects[currentProjectIdx].tasks.slice(
              currentTaskIdx + 1
            ),
          ];
          const newTimeInMinutes = getSumOfTime(
            state.projects[currentProjectIdx].tasks
          );

          state.projects[currentProjectIdx].time =
            transformMinutesToHoursStr(newTimeInMinutes);
        }
      } else if (state.otherProjects.length === 1) {
        state.otherProjects = initialState.otherProjects;
      } else {
        const otherProjectTaskIdx = state.otherProjects.findIndex(
          (other) => other.name === name
        );

        if (otherProjectTaskIdx !== -1) {
          state.otherProjects = [
            ...state.otherProjects.slice(0, otherProjectTaskIdx),
            ...state.otherProjects.slice(otherProjectTaskIdx + 1),
          ];
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getWorkingHours.pending, (state) => {
        state.fetchingWorkingHoursStatus = StatusEnum.PENDING;
      })
      .addCase(thunks.getWorkingHours.fulfilled, (state, { payload }) => {
        if (payload) {
          state.workingHours = payload.hours;
        }
        state.fetchingWorkingHoursStatus = StatusEnum.SUCCESS;
      })
      .addCase(thunks.getWorkingHours.rejected, (state) => {
        state.fetchingWorkingHoursStatus = StatusEnum.FAIL;
      })

      .addCase(thunks.getLatestReport.pending, (state) => {
        state.fetchingLatestReportStatus = StatusEnum.PENDING;
      })
      .addCase(thunks.getLatestReport.fulfilled, (state, { payload }) => {
        if (!Array.isArray(payload) && !payload.sent) {
          const { projects, otherProjects, ...rest } = payload;
          state.currentReport = rest;
          state.otherProjects = otherProjects.map((other) =>
            otherProjectModel(other)
          );
          state.projects = projects.map((project) => projectModel(project));
          state.period = payload.period;
          state.isSaved = true;
        }
        state.fetchingLatestReportStatus = StatusEnum.SUCCESS;
      })
      .addCase(thunks.getLatestReport.rejected, (state) => {
        state.fetchingLatestReportStatus = StatusEnum.FAIL;
      })

      .addCase(thunks.importFromTimeDoctor.pending, (state) => {
        state.fetchingDataFromTimeDoctorStatus = StatusEnum.PENDING;
      })
      .addCase(thunks.importFromTimeDoctor.fulfilled, (state, { payload }) => {
        if (payload) {
          const { projects, otherProjects, ...rest } = payload;
          state.currentReport = rest;
          state.otherProjects = otherProjects.map((other) =>
            otherProjectModel(other)
          );
          state.projects = projects.map((project) => projectModel(project));
          state.isSaved = true;
        }
        state.fetchingDataFromTimeDoctorStatus = StatusEnum.SUCCESS;
      })
      .addCase(thunks.importFromTimeDoctor.rejected, (state) => {
        state.fetchingDataFromTimeDoctorStatus = StatusEnum.FAIL;
      })

      .addCase(thunks.saveReport.pending, (state) => {
        state.saveReportStatus = StatusEnum.PENDING;
      })
      .addCase(thunks.saveReport.fulfilled, (state, { payload }) => {
        const { otherProjects, ...rest } = payload;
        state.currentReport = rest;
        state.otherProjects = otherProjects.map((other) =>
          otherProjectModel(other)
        );
        state.isSaved = true;
        state.saveReportStatus = StatusEnum.SUCCESS;
      })
      .addCase(thunks.saveReport.rejected, (state) => {
        state.saveReportStatus = StatusEnum.FAIL;
      })

      .addCase(thunks.sendReport.pending, (state) => {
        state.sendReportStatus = StatusEnum.PENDING;
      })
      .addCase(thunks.sendReport.fulfilled, (state) => {
        state.sendReportStatus = StatusEnum.SUCCESS;
      })
      .addCase(thunks.sendReport.rejected, (state) => {
        state.sendReportStatus = StatusEnum.FAIL;
      })

      .addCase(thunks.editReport.pending, (state) => {
        state.editReportStatus = StatusEnum.PENDING;
      })
      .addCase(thunks.editReport.fulfilled, (state, { payload }) => {
        const { otherProjects, projects, ...rest } = payload;
        state.currentReport = rest;
        state.projects = projects.map((project) => projectModel(project));
        state.otherProjects = otherProjects.map((other) =>
          otherProjectModel(other)
        );
        state.isSaved = true;
        state.editReportStatus = StatusEnum.SUCCESS;
      })
      .addCase(thunks.editReport.rejected, (state) => {
        state.editReportStatus = StatusEnum.FAIL;
      })

      .addCase(thunks.deleteReport.pending, (state) => {
        state.deleteReportStatus = StatusEnum.PENDING;
      })
      .addCase(thunks.deleteReport.fulfilled, (state) => {
        state.deleteReportStatus = StatusEnum.SUCCESS;
      })
      .addCase(thunks.deleteReport.rejected, (state) => {
        state.deleteReportStatus = StatusEnum.FAIL;
      });
  },
});

const createReport = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { createReport };
export default slice.reducer;
