import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from 'utils/const';
import { IPaginate } from 'types/common';
import { walletModel } from 'models/walletModel';
import { thunks } from './thunks';
import { selectors } from './selectors';
import { IWorker, IWorkerUpdate } from './types';

interface IWorkers {
  statusGetWorker: StatusEnum;
  statusCreateWorkers: StatusEnum;
  statusGetWorkerId: StatusEnum;
  statusUpdateWorker: StatusEnum;
  statusDeleteWorker: StatusEnum;
  statusRestoreWorker: StatusEnum;
  pagination: IPaginate;
  workers: IWorker<'customWallet'>[];
  worker: IWorker | undefined;
  workerDataForUpdate: IWorkerUpdate;
}

const initialState: IWorkers = {
  workers: [],
  worker: undefined,
  workerDataForUpdate: {},
  pagination: {},
  statusGetWorker: StatusEnum.IDLE,
  statusCreateWorkers: StatusEnum.IDLE,
  statusGetWorkerId: StatusEnum.IDLE,
  statusUpdateWorker: StatusEnum.IDLE,
  statusDeleteWorker: StatusEnum.IDLE,
  statusRestoreWorker: StatusEnum.IDLE,
};

const slice = createSlice({
  name: 'workers',
  initialState,
  reducers: {
    SET_STATUS_CREATE: (state, { payload }) => {
      state.statusCreateWorkers = payload;
    },
    SET_STATUS_USER: (state) => {
      state.statusGetWorkerId = StatusEnum.IDLE;
      state.statusUpdateWorker = StatusEnum.IDLE;
      state.statusDeleteWorker = StatusEnum.IDLE;
      state.statusRestoreWorker = StatusEnum.IDLE;
    },
    CLEAR_WORKER: (state) => {
      state.worker = undefined;
      state.workerDataForUpdate = {};
    },
    SET_WALLET_BALANCE: (
      state,
      { payload }: PayloadAction<{ count: number; name: 'send' | 'personal' }>
    ) => {
      const { count, name } = payload;

      // @ts-ignore
      state.workerDataForUpdate.wallets[name].balance += count;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getWorkers.pending, (state) => {
        state.statusGetWorker = StatusEnum.PENDING;
      })
      .addCase(thunks.getWorkers.fulfilled, (state, { payload }) => {
        const { current_page, per_page, total } = payload.meta;
        state.pagination = {
          perPage: per_page,
          current: current_page,
          total,
        };
        state.workers = payload.data.map(({ wallets, ...rest }) => ({
          ...rest,
          wallets: walletModel(wallets),
        }));
        state.statusGetWorker = StatusEnum.SUCCESS;
      })
      .addCase(thunks.getWorkers.rejected, (state) => {
        state.statusGetWorker = StatusEnum.FAIL;
      })

      .addCase(thunks.getWorkersForScroll.pending, (state) => {
        state.statusGetWorker = StatusEnum.PENDING;
      })
      .addCase(thunks.getWorkersForScroll.fulfilled, (state, { payload }) => {
        const { current_page, per_page, total } = payload.meta;
        state.pagination = {
          perPage: per_page,
          current: current_page,
          total,
        };
        state.workers = [
          ...state.workers,
          ...payload.data.map(({ wallets, ...rest }) => ({
            ...rest,
            wallets: walletModel(wallets),
          })),
        ];
        state.statusGetWorker = StatusEnum.SUCCESS;
      })
      .addCase(thunks.getWorkersForScroll.rejected, (state) => {
        state.statusGetWorker = StatusEnum.FAIL;
      })

      .addCase(thunks.createWorkers.pending, (state) => {
        state.statusCreateWorkers = StatusEnum.PENDING;
      })
      .addCase(thunks.createWorkers.fulfilled, (state) => {
        state.statusCreateWorkers = StatusEnum.SUCCESS;
      })
      .addCase(thunks.createWorkers.rejected, (state) => {
        state.statusCreateWorkers = StatusEnum.FAIL;
      })
      .addCase(thunks.getUserId.pending, (state) => {
        state.statusGetWorkerId = StatusEnum.PENDING;
      })
      .addCase(thunks.getUserId.fulfilled, (state, { payload }) => {
        state.statusGetWorkerId = StatusEnum.SUCCESS;
        const { userInfo, wallets, ...rest } = payload;

        const data = {
          ...userInfo,
          ...rest,
          wallets: walletModel(wallets!),
        };

        // eslint-disable-next-line no-restricted-syntax,guard-for-in
        for (const key in data) {
          const target = data[key as keyof typeof data];
          if (target === null) {
            delete data[key as keyof typeof data];
          }
        }

        state.workerDataForUpdate = data;
      })
      .addCase(thunks.getUserId.rejected, (state) => {
        state.statusGetWorkerId = StatusEnum.FAIL;
      })
      .addCase(thunks.updateWorker.pending, (state) => {
        state.statusUpdateWorker = StatusEnum.PENDING;
      })
      .addCase(thunks.updateWorker.fulfilled, (state) => {
        state.statusUpdateWorker = StatusEnum.SUCCESS;
      })
      .addCase(thunks.updateWorker.rejected, (state) => {
        state.statusUpdateWorker = StatusEnum.FAIL;
      })
      .addCase(thunks.deleteWorker.pending, (state) => {
        state.statusDeleteWorker = StatusEnum.PENDING;
      })
      .addCase(thunks.deleteWorker.fulfilled, (state) => {
        state.workerDataForUpdate.deletedAt = String(new Date());
        state.statusDeleteWorker = StatusEnum.SUCCESS;
      })
      .addCase(thunks.deleteWorker.rejected, (state) => {
        state.statusDeleteWorker = StatusEnum.FAIL;
      })
      .addCase(thunks.restoreWorker.pending, (state) => {
        state.statusRestoreWorker = StatusEnum.PENDING;
      })
      .addCase(thunks.restoreWorker.fulfilled, (state) => {
        state.workerDataForUpdate.deletedAt = null;
        state.statusRestoreWorker = StatusEnum.SUCCESS;
      })
      .addCase(thunks.restoreWorker.rejected, (state) => {
        state.statusRestoreWorker = StatusEnum.FAIL;
      });
  },
});

const workers = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { workers };
export default slice.reducer;
