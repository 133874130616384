import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from 'utils/const';
import { IOtherProjectOrig, IProjectOrig } from 'types/project';
import { thunks } from './thunks';
import { selectors } from './selectors';
import { IReport } from '../createReport/types';

interface ReportDetailsState {
  data: IReport<string> | null;
  projects: IProjectOrig[];
  otherProjects: IOtherProjectOrig[];
  workingHours: number;
  totalWorkedInMinutes: number;
  fetchingDetailsStatus: StatusEnum;
  fetchingWorkingHoursStatus: StatusEnum;
}

const statuses = {
  fetchingDetailsStatus: StatusEnum.IDLE,
  fetchingWorkingHoursStatus: StatusEnum.IDLE,
} as const;

const initialState: ReportDetailsState = {
  data: null,
  projects: [],
  otherProjects: [],
  workingHours: 0,
  totalWorkedInMinutes: 0,
  ...statuses,
};

const slice = createSlice({
  name: 'reportDetails',
  initialState,
  reducers: {
    RESET_STATE: (state) => {
      Object.assign(state, initialState);
    },
    RESET_STATUS: (
      state,
      { payload }: PayloadAction<keyof typeof statuses>
    ) => {
      state[payload] = StatusEnum.IDLE;
    },

    SET_TOTAL_WORKED_IN_MINUTES: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.totalWorkedInMinutes = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getReportDetails.pending, (state) => {
        state.fetchingDetailsStatus = StatusEnum.PENDING;
      })
      .addCase(thunks.getReportDetails.fulfilled, (state, { payload }) => {
        state.data = payload;
        state.projects = payload.projects;
        state.otherProjects = payload.otherProjects;
        state.fetchingDetailsStatus = StatusEnum.SUCCESS;
      })
      .addCase(thunks.getReportDetails.rejected, (state) => {
        state.fetchingDetailsStatus = StatusEnum.FAIL;
      })

      .addCase(thunks.getWorkingHours.pending, (state) => {
        state.fetchingWorkingHoursStatus = StatusEnum.PENDING;
      })
      .addCase(thunks.getWorkingHours.fulfilled, (state, { payload }) => {
        if (payload) {
          state.workingHours = payload.hours;
        }
        state.fetchingWorkingHoursStatus = StatusEnum.SUCCESS;
      })
      .addCase(thunks.getWorkingHours.rejected, (state) => {
        state.fetchingWorkingHoursStatus = StatusEnum.FAIL;
      });
  },
});

const reportDetails = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { reportDetails };
export default slice.reducer;
