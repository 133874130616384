import {
  IOtherProjectCustom,
  IOtherProjectOrig,
  IProjectCustom,
  IProjectOrig,
} from 'types/project';

export const projectModel = (project: IProjectOrig): IProjectCustom => {
  const { time, name, id, tasks } = project;
  const newTasks = tasks.map((task) => {
    return {
      ...task,
      projectName: name,
    };
  });

  return {
    id,
    projectId: id,
    name,
    time,
    tasks: newTasks,
  };
};

export const otherProjectModel = (
  project: IOtherProjectOrig
): IOtherProjectCustom => {
  const { id, time, ...rest } = project;

  return {
    projectId: id,
    time: time || '0:00',
    ...rest,
  };
};
