import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';
import { IGetProjectsData } from './types';

const getProjects = createAsyncThunk('additional/getProjects', async () => {
  try {
    const { data } = await api.additional.getProjects();
    return data as IGetProjectsData;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      handleServerErrors(err);
    }
    throw err;
  }
});

const exportReport = createAsyncThunk(
  'additional/exportReport',
  async (reportId: number) => {
    try {
      const { data } = await api.additional.exportReport(reportId);
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleServerErrors(err);
      }
      throw err;
    }
  }
);

export const thunks = { getProjects, exportReport };
