import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';
import { ITransParam } from 'store/user/tipTops/types';

const tipTopsModule = {
  getBalance() {
    return http.get(WEB_API_ROUTES.user.tipTops.getBalance);
  },

  getTransactions(params: ITransParam) {
    return http.get(WEB_API_ROUTES.user.tipTops.getTransactions, { params });
  },
};

export { tipTopsModule };
