import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';
import { alert } from 'utils/alert';
import {
  IEditReportParam,
  IGetWorkingHoursData,
  IGetWorkingHoursParams,
  IImportFromTimeDoctorParams,
  IReport,
  ISaveReportParam,
} from './types';

const getWorkingHours = createAsyncThunk(
  'createReport/getWorkingHours',
  async (params: IGetWorkingHoursParams) => {
    try {
      const { data } = await api.additional.getWorkingHours(params);
      return data as IGetWorkingHoursData;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleServerErrors(err);
      }
      throw err;
    }
  }
);

const getLatestReport = createAsyncThunk(
  'createReport/getLatestReport',
  async () => {
    try {
      const { data } = await api.user.createReport.getLatestReport();
      return data.data as IReport;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleServerErrors(err);
      }
      throw err;
    }
  }
);

const importFromTimeDoctor = createAsyncThunk(
  'createReport/importFromTimeDoctor',
  async (params: IImportFromTimeDoctorParams) => {
    try {
      const { data } = await api.user.createReport.importFromTimeDoctor(params);
      return data.data as IReport;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleServerErrors(err);
      }
      throw err;
    }
  }
);

const saveReport = createAsyncThunk(
  'createReport/saveReport',
  async (fd: ISaveReportParam) => {
    try {
      const { data } = await api.user.createReport.saveReport(fd);
      return data.data as IReport;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleServerErrors(err);
      }
      throw err;
    }
  }
);

const sendReport = createAsyncThunk(
  'createReport/sendReport',
  async (reportId: number) => {
    try {
      const { data } = await api.user.createReport.sendReport(reportId);
      alert({
        title: 'Юхууууу!',
        message: 'Ви успішно надіслали звіт бухгалтеру, чекайте на гроші.',
      });
      return data;
    } catch (err) {
      alert({
        type: 'error',
        title: 'Не вийшло :(',
        message: 'Звіт не було надіслано бухгалтеру, грошей не буде.',
      });
      throw err;
    }
  }
);

const editReport = createAsyncThunk(
  'createReport/editReport',
  async ({ reportId, formData }: IEditReportParam) => {
    try {
      const { data } = await api.user.createReport.editReport(
        reportId,
        formData
      );
      return data.data as IReport;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleServerErrors(err);
      }
      throw err;
    }
  }
);

const deleteReport = createAsyncThunk(
  'createReport/deleteReport',
  async (reportId: number) => {
    try {
      const { data } = await api.user.createReport.deleteReport(reportId);
      alert({ message: data.message });
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleServerErrors(err);
      }
      throw err;
    }
  }
);

export const thunks = {
  getWorkingHours,
  getLatestReport,
  importFromTimeDoctor,
  saveReport,
  sendReport,
  editReport,
  deleteReport,
};
