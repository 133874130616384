import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';
import { IMakeOrder } from 'store/user/topShop/types';
import { IPaginate } from 'types/common';

const topShopModule = {
  getProduct(productId: string) {
    return http.get(
      WEB_API_ROUTES.user.topShop.productById.replace('{product}', productId)
    );
  },

  makeOrder({ productId, formData }: IMakeOrder) {
    return http.post(
      WEB_API_ROUTES.user.topShop.makeOrder.replace('{product}', productId),
      formData
    );
  },

  getProducts(params: IPaginate) {
    return http.get(WEB_API_ROUTES.user.topShop.getProducts, { params });
  },
};

export { topShopModule };
