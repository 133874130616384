import { createGlobalStyle, css } from 'styled-components';

import { device } from 'utils/device';

export const GlobalStyles = createGlobalStyle`
  ${({ theme }) => css`
    * {
      &::-webkit-scrollbar {
        height: 4px;
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background: ${theme.white};
        border-radius: 2px;
      }

      &::-webkit-scrollbar-thumb {
        background: ${theme.iron};
        border-radius: 2px;
      }
    }

    body {
      font-family: 'Inter', sans-serif;
      color: ${theme.thunder};

      .Toastify__toast-container {
        top: 25px;
        right: 25px;
        width: 360px;
        word-break: break-word;

        .Toastify__toast {
          padding: 0;
          min-height: auto;
          font-size: 12px;
          line-height: normal;
          box-shadow: 0 4px 6px 1px rgba(74, 80, 87, 0.13);

          &--error {
            color: ${theme.white};
          }

          &-body {
            padding: 13px 15px;
            margin: 0;
          }

          &-icon {
            width: 16px;
          }
        }

        @media only screen and (${device.sm}) {
          top: 0;
          right: 16px;
          left: 16px;
          width: auto;
        }
      }

      &.lock {
        overflow: hidden !important;
      }
    }
  `}
`;
