import { createGlobalStyle } from 'styled-components';

export const ComponentsStyle = createGlobalStyle`
  .swal2-container {
    padding: 16px;
      background: ${({ theme }) => theme.zeusLight} !important;

    .swal2-popup {
      display: flex !important;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 400px;
      padding: 45px 49px 55px;
      border-radius: 12px;
      box-shadow: ${({ theme }) => theme.popupBoxShadow};

      .swal2-icon {
        display: block;
        width: 76px;
        height: 76px;
        margin: 0 0 22px;
        border: none;
        
        & > svg {
          width: 100%;
          height: 100%;
        }
      }
      
      .swal2-title {
        padding: 0;
        font-size: 18px;
        font-weight: 600;
        color: ${({ theme }) => theme.thunder};
        margin-bottom: 4px;
      }
      
      .swal2-html-container {
        width: 100%;
        max-width: 302px;
        margin: 0;
        text-align: center;
        font-size: 14px;
        color: ${({ theme }) => theme.steelGrey};
      }
    }
  }
`;
