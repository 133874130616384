const appDateFormat = 'dd.MM.yyyy';
const monthFormat = 'LLLL';
const monthWithDateFormat = 'dd MMMM (EEEE)';
const monthWithYearFormat = 'LLLL yyyy';
const periodFormat = 'yyyy-MM-dd';

const dayDateRange = 'd MMM';
const sentReportFormat = 'dd EEEE, LLLL';
const sentTopFormat = 'dd EEEE, yyyy';

export {
  appDateFormat,
  monthFormat,
  monthWithDateFormat,
  periodFormat,
  sentReportFormat,
  monthWithYearFormat,
  sentTopFormat,
  dayDateRange,
};
