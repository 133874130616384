import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';
import { DatesRangeFormatted } from 'store/user/reportHistory/types';

const reportHistory = {
  getReportHistory(params?: DatesRangeFormatted) {
    return http.get(WEB_API_ROUTES.user.reportHistory.getReportHistory, {
      params,
    });
  },
};

export { reportHistory };
