import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';
import {
  IGetWorkingHoursData,
  IGetWorkingHoursParams,
  IReport,
} from '../createReport/types';

const getReportDetails = createAsyncThunk(
  'reportDetails/getReportDetails',
  async (reportId: string) => {
    try {
      const { data } = await api.user.reportDetails.getReportDetails(reportId);
      return data.data as IReport<string>;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleServerErrors(err);
      }
      throw err;
    }
  }
);

const getWorkingHours = createAsyncThunk(
  'reportDetails/getWorkingHours',
  async (params: IGetWorkingHoursParams) => {
    try {
      const { data } = await api.additional.getWorkingHours(params);
      return data as IGetWorkingHoursData;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleServerErrors(err);
      }
      throw err;
    }
  }
);

export const thunks = {
  getReportDetails,
  getWorkingHours,
};
