import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { walletModel } from 'models/walletModel';
import { StatusEnum } from 'utils/const';
import { thunks } from './thunks';
import { selectors } from './selectors';
import { IUserOrder, OrderStatusEnum } from './types';

interface IWorkers {
  order: null | IUserOrder<'customWallet'>;
  getOrderStatus: StatusEnum;
}

const statuses = {
  getOrderStatus: StatusEnum.IDLE,
};

const initialState: IWorkers = {
  order: null,
  ...statuses,
};

const slice = createSlice({
  name: 'shop/order',
  initialState,
  reducers: {
    RESET_ORDER: (state) => {
      state.order = null;
      state.getOrderStatus = StatusEnum.IDLE;
    },
    SET_WALLET_BALANCE: (
      state,
      { payload }: PayloadAction<{ count: number; name: 'send' | 'personal' }>
    ) => {
      const { count, name } = payload;

      if (state.order) {
        state.order.wallets[name].balance += count;
      }
    },
    RESET_STATUS: (
      state,
      { payload }: PayloadAction<keyof typeof statuses>
    ) => {
      state[payload] = StatusEnum.IDLE;
    },
    UPDATE_ORDER_STATUS: (state, { payload }: PayloadAction<number>) => {
      if (state.order) {
        state.order.orders = state.order?.orders.map((order) => {
          if (order.id === payload) {
            return {
              ...order,
              status: OrderStatusEnum.COMPLETED,
            };
          }

          return order;
        });
      }
    },
    SET_COMPLETED_TO_ALL_ORDER_STATUSES: (state) => {
      if (state.order) {
        state.order.orders = state.order.orders.map((order) => ({
          ...order,
          status: OrderStatusEnum.COMPLETED,
        }));
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getOrderById.pending, (state) => {
        state.getOrderStatus = StatusEnum.PENDING;
      })
      .addCase(thunks.getOrderById.fulfilled, (state, { payload }) => {
        const { wallets, ...rest } = payload;
        state.order = {
          ...rest,
          wallets: walletModel(wallets),
        };
        state.getOrderStatus = StatusEnum.SUCCESS;
      })
      .addCase(thunks.getOrderById.rejected, (state) => {
        state.getOrderStatus = StatusEnum.FAIL;
      });
  },
});

const shopOrders = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { shopOrders };
export default slice.reducer;
