import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';
import { DatesRangeFormatted, IReport } from './types';

const getReportHistory = createAsyncThunk(
  'reportHistory/getReportHistory',
  async (date?: DatesRangeFormatted) => {
    try {
      const { data } = await api.user.reportHistory.getReportHistory(date);
      return data.data as IReport[];
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleServerErrors(err);
      }
      throw err;
    }
  }
);

export const thunks = {
  getReportHistory,
};
