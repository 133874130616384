import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';
import { IPaginate } from 'types/common';

const shop = {
  createProduct(formData: FormData) {
    return http.post(WEB_API_ROUTES.admin.shop.createProduct, formData);
  },

  getListProduct(params: IPaginate) {
    return http.get(WEB_API_ROUTES.admin.shop.getListProduct, { params });
  },

  getProduct(id: string) {
    return http.get(
      WEB_API_ROUTES.admin.shop.getProduct.replace('{product}', id)
    );
  },

  editProduct(formData: { data: FormData; id: string }) {
    return http.post(
      WEB_API_ROUTES.admin.shop.editProduct.replace('{product}', formData.id),
      formData.data
    );
  },

  deleteProduct(idProduct: string) {
    return http.delete(
      WEB_API_ROUTES.admin.shop.deleteProduct.replace('{product}', idProduct)
    );
  },

  getOrdersForProduct(productId: string) {
    return http.get(
      WEB_API_ROUTES.admin.shop.getOrdersForProduct.replace(
        '{product}',
        productId
      )
    );
  },

  getOrdersList(params: IPaginate) {
    return http.get(WEB_API_ROUTES.admin.shop.getOrdersList, { params });
  },

  getOrderById(params: { orderId: string }) {
    return http.get(
      WEB_API_ROUTES.admin.shop.getOrderById.replace('{user}', params.orderId)
    );
  },
};

export { shop };
