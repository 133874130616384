import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';
import { alert } from 'utils/alert';
import { IFinishOrder, ISendTokens } from './types';

const finishOrder = createAsyncThunk(
  'shop/finishOrder',
  async ({ orderId, fd }: IFinishOrder) => {
    try {
      const { data } = await api.admin.adminAdditional.finishOrder(orderId, fd);
      alert({ message: data.message });
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleServerErrors(err);
      }
      throw err;
    }
  }
);

const finishAllOrders = createAsyncThunk(
  'shop/finishAllOrders',
  async (userId: number) => {
    try {
      const { data } = await api.admin.adminAdditional.finishAllOrder(userId);
      alert({ message: data.message });
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleServerErrors(err);
      }
      throw err;
    }
  }
);

const finishAllOrderForProduct = createAsyncThunk(
  'shop/finishAllOrderForProduct',
  async (productId: number) => {
    try {
      const { data } = await api.admin.adminAdditional.finishAllOrderForProduct(
        productId
      );
      alert({ message: data.message });
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleServerErrors(err);
      }
      throw err;
    }
  }
);

const sendTokens = createAsyncThunk(
  'shop/sendTokens',
  async ({ fd }: ISendTokens) => {
    try {
      const { data } = await api.admin.adminAdditional.sendTokens(fd);
      alert({ message: data.message });
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleServerErrors(err);
      }
      throw err;
    }
  }
);

const getMonthlyTipTops = createAsyncThunk(
  'admin/additional/getMonthlyTipTops',
  async () => {
    try {
      const { data } = await api.admin.adminAdditional.getMonthlyTipTops();
      return data.data.amount as number;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleServerErrors(err);
      }
      throw err;
    }
  }
);

const setMonthlyTipTops = createAsyncThunk(
  'admin/additional/setMonthlyTipTops',
  async (amount: number) => {
    try {
      const { data } = await api.admin.adminAdditional.setMonthlyTipTops(
        amount
      );
      alert({ title: 'Зміни збережено!', message: data.message });
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleServerErrors(err);
      }
      throw err;
    }
  }
);

export const thunks = {
  finishOrder,
  sendTokens,
  finishAllOrders,
  finishAllOrderForProduct,
  getMonthlyTipTops,
  setMonthlyTipTops,
};
