import { RootState } from 'store';

export const selectors = {
  product: (state: RootState) => state.topShop.product,
  getProductStatus: (state: RootState) => state.topShop.getProductStatus,
  makeOrderStatus: (state: RootState) => state.topShop.makeOrderStatus,
  dataProduct: (state: RootState) => state.topShop.dataProduct,
  metaProductList: (state: RootState) => state.topShop.metaProductList,
  statusProduct: (state: RootState) => state.topShop.statusProduct,
};
