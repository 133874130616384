import { RootState } from 'store';

export const selectors = {
  workersData: (state: RootState) => state.workers.workers,
  worker: (state: RootState) => state.workers.worker,
  meta: (state: RootState) => state.workers.pagination,
  statusGetWorker: (state: RootState) => state.workers.statusGetWorker,
  statusCreateWorkers: (state: RootState) => state.workers.statusCreateWorkers,
  statusGetWorkerId: (state: RootState) => state.workers.statusGetWorkerId,
  workerDataForUpdate: (state: RootState) => state.workers.workerDataForUpdate,
  statusUpdateWorker: (state: RootState) => state.workers.statusUpdateWorker,
  statusDeleteWorker: (state: RootState) => state.workers.statusDeleteWorker,
  statusRestoreWorker: (state: RootState) => state.workers.statusRestoreWorker,
};
