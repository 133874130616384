import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { ReactComponent as SuccessIcon } from 'assets/img/icons/indicators/success.svg';

import { ReactComponent as ErrorIcon } from 'assets/img/icons/indicators/warn.svg';

interface AlertProps {
  message?: string;
  title?: string;
  type?: 'success' | 'error';
}

export async function alert({ type = 'success', title, message }: AlertProps) {
  return withReactContent(Swal).fire({
    iconHtml: type === 'success' ? <SuccessIcon /> : <ErrorIcon />,
    showConfirmButton: false,
    titleText:
      title || (type === 'success' ? 'Успіх!' : 'Щось пішло не так :('),
    html: message,
    timer: 3000,
  });
}
