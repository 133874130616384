import { IMetaType, IPaginate, ISelect } from 'types/common';

const onExportFile = (file: string, fileName: string) => {
  const blobFile = new Blob([file]);
  const href = URL.createObjectURL(blobFile);
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

const prepareReportName = (period: string, userName?: string): string => {
  return `${userName ? userName.replace(' ', '_') : 'name'}_${period
    .replaceAll('-', '_')
    .replaceAll(' ', '_')}.xlsx`;
};

const objToSelectFormat = (label: string, value: string | number): ISelect => {
  return {
    label,
    value,
  };
};

const objectToFormData = (obj: { [key: string]: any }): FormData => {
  const formData = new FormData();

  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    if (Object.hasOwn(obj, key)) {
      const value = obj[key];
      formData.append(key, value);
    }
  }

  return formData;
};

const svgStartAnimation = (svg: SVGSVGElement): void => {
  svg.childNodes.forEach((item) => {
    if (item.nodeName === 'animate') {
      (item as SVGAnimationElement).beginElement();
    }
  });
};

const getPaginationObj = (meta: IMetaType): IPaginate => {
  const { current_page, per_page, total } = meta;
  return {
    perPage: per_page,
    current: current_page,
    total,
  };
};

export {
  onExportFile,
  objToSelectFormat,
  prepareReportName,
  svgStartAnimation,
  objectToFormData,
  getPaginationObj,
};
