import { css, keyframes } from 'styled-components';

const Block = ({
  w = 'auto',
  h = 'auto',
  p = 0,
  m = 0,
  b = 'none',
  bg = 'transparent',
}) => css`
  width: ${w};
  height: ${h};
  padding: ${p};
  margin: ${m};
  border: ${b};
  background: ${bg};
`;

const Flex = ({
  align = 'center',
  justify = 'flex-start',
  direction = 'row',
  gap = '0px',
}) => css`
  display: flex;
  align-items: ${align};
  flex-direction: ${direction};
  gap: ${gap};
  justify-content: ${justify};
`;

const ScrollBarHidden = () => css`
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
`;

const TextEllipsis = ({ width }: { width: string }) => css`
  width: ${width};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TextEllipsisForLines = ({ linesNumber = 4 }) => css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${linesNumber};
  -webkit-box-orient: vertical;
`;

const Circle = ({ size = '21px' }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${size};
  height: ${size};
  border: 1px solid transparent;
  border-radius: 20px;
  margin: 0 1px;
  padding: 0 8px;
  line-height: 1;
  outline: 0;
  cursor: pointer;
`;

const IconActions = () => css`
  &:hover {
    fill: ${({ theme }) => theme.smokyBlack};
  }

  &:active {
    fill: ${({ theme }) => theme.blueLotus};
  }
`;

const PopupAnimation = keyframes`
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
`;

export {
  Block,
  Flex,
  ScrollBarHidden,
  TextEllipsis,
  TextEllipsisForLines,
  Circle,
  IconActions,
  PopupAnimation,
};
