import { RootState } from 'store';

export const selectors = {
  currentReport: (state: RootState) => state.createReport.currentReport,
  projects: (state: RootState) => state.createReport.projects,
  otherProjects: (state: RootState) => state.createReport.otherProjects,
  period: (state: RootState) => state.createReport.period,
  totalWorkedInMinutes: (state: RootState) =>
    state.createReport.totalWorkedInMinutes,
  workingHours: (state: RootState) => state.createReport.workingHours,
  isSaved: (state: RootState) => state.createReport.isSaved,
  fetchingWorkingHoursStatus: (state: RootState) =>
    state.createReport.fetchingWorkingHoursStatus,
  fetchingLatestReportStatus: (state: RootState) =>
    state.createReport.fetchingLatestReportStatus,
  fetchingDataFromTimeDoctorStatus: (state: RootState) =>
    state.createReport.fetchingDataFromTimeDoctorStatus,
  saveReportStatus: (state: RootState) => state.createReport.saveReportStatus,
  sendReportStatus: (state: RootState) => state.createReport.sendReportStatus,
  editReportStatus: (state: RootState) => state.createReport.editReportStatus,
  deleteReportStatus: (state: RootState) =>
    state.createReport.deleteReportStatus,
};
