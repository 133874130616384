import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';
import {
  IFinishOrderFormData,
  ISendTokensFormData,
} from 'store/admin/additional/types';

const additional = {
  finishOrder(orderId: number, fd: IFinishOrderFormData) {
    return http.put(
      WEB_API_ROUTES.admin.additional.finishOrder.replace(
        '{order}',
        orderId.toString()
      ),
      fd
    );
  },

  finishAllOrder(userId: number) {
    return http.post(
      WEB_API_ROUTES.admin.additional.finishAllOrder.replace(
        '{user}',
        userId.toString()
      )
    );
  },

  finishAllOrderForProduct(productId: number) {
    return http.post(
      WEB_API_ROUTES.admin.additional.finishAllOrderForProduct.replace(
        '{product}',
        productId.toString()
      )
    );
  },

  sendTokens(fd: ISendTokensFormData) {
    return http.post(WEB_API_ROUTES.admin.additional.sendTokens, fd);
  },

  getMonthlyTipTops() {
    return http.get(WEB_API_ROUTES.admin.additional.monthlyTipTops);
  },

  setMonthlyTipTops(amount: number) {
    return http.post(WEB_API_ROUTES.admin.additional.monthlyTipTops, {
      amount,
    });
  },
};

export { additional };
