import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from 'utils/const';
import { getPaginationObj } from 'utils/prepare';
import { IPaginate } from 'types/common';
import { thunks } from './thunks';
import { selectors } from './selectors';
import { IOrder, IProduct } from './types';

interface IWorkers {
  listProduct: IProduct[];
  ordersList: IOrder[];
  paginationProduct: IPaginate;
  paginationOrders: IPaginate;
  getOrdersList: StatusEnum;
  getProductsStatus: StatusEnum;
}

const statuses = {
  getProductsStatus: StatusEnum.IDLE,
  getOrdersList: StatusEnum.IDLE,
};

const initialState: IWorkers = {
  paginationProduct: {},
  paginationOrders: {},
  listProduct: [],
  ordersList: [],
  ...statuses,
};

const slice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    RESET_STATUS: (
      state,
      { payload }: PayloadAction<keyof typeof statuses>
    ) => {
      state[payload] = StatusEnum.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(thunks.getProductList.pending, (state) => {
        state.getProductsStatus = StatusEnum.PENDING;
      })
      .addCase(thunks.getProductList.fulfilled, (state, { payload }) => {
        state.paginationProduct = getPaginationObj(payload.meta);
        state.listProduct = payload.data;
        state.getProductsStatus = StatusEnum.SUCCESS;
      })
      .addCase(thunks.getProductList.rejected, (state) => {
        state.getProductsStatus = StatusEnum.FAIL;
      })

      .addCase(thunks.getOrdersList.pending, (state) => {
        state.getOrdersList = StatusEnum.PENDING;
      })
      .addCase(thunks.getOrdersList.fulfilled, (state, { payload }) => {
        state.paginationOrders = getPaginationObj(payload.meta);
        state.ordersList = payload.data;
        state.getOrdersList = StatusEnum.SUCCESS;
      })
      .addCase(thunks.getOrdersList.rejected, (state) => {
        state.getOrdersList = StatusEnum.FAIL;
      });
  },
});

const shop = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { shop };
export default slice.reducer;
