import { hoursToMinutes, minutesToHours } from 'date-fns';

import { ProjectOrTaskToSum } from 'types/project';
import { projectsIds } from 'utils/const';

export const getPrevMonth = (date: Date): Date => {
  return new Date(date.getFullYear(), date.getMonth(), 0);
};

export const transformHoursStrToMinutes = (hours: string): number => {
  const arr = hours.split(':').map((item) => parseInt(item, 10));
  return hoursToMinutes(arr[0]) + arr[1];
};

export const mmFormat = (minutes: number): string => {
  return minutes.toString().padStart(2, '0');
};

export const transformMinutesToHoursStr = (
  totalMinutes: number,
  alwaysWithMinutes = true
): string => {
  const hours = minutesToHours(totalMinutes);
  const minutes = totalMinutes % 60;

  if (alwaysWithMinutes) {
    return `${hours}:${mmFormat(minutes)}`;
  }
  if (!minutes) {
    return `${hours}`;
  }
  return `${hours}:${mmFormat(minutes)}`;
};

export const getHoursAndMinutesFromString = (
  hours: string
): { hours: string; minutes: string } => {
  const arr = hours.split(':');
  return {
    hours: arr[0],
    minutes: arr[1],
  };
};

export const prepareTimeInProject = (
  hours: number,
  minutes: number
): string => {
  return `${hours}:${mmFormat(minutes)}`;
};

export const getSumOfTime = (projects: ProjectOrTaskToSum[]) => {
  return projects.reduce((accumulator, currentProject) => {
    if (currentProject.projectId === projectsIds.UNPAID_VACATION) {
      return accumulator;
    }
    return accumulator + transformHoursStrToMinutes(currentProject.time);
  }, 0);
};
