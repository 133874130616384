import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';
import { IDataCreateUser } from 'components/Admin/WorkersPage/ModalForm/config';
import { IPaginate } from 'types/common';
import { IWorker, IWorkerUpdate, TypeWorkers } from './types';

const getWorkers = createAsyncThunk<TypeWorkers, Omit<IPaginate, 'total'>>(
  'workers/getWorkers',
  async (params) => {
    try {
      const { data } = await api.admin.workers.getWorkers(params);
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleServerErrors(err);
      }
      throw err;
    }
  }
);

const getWorkersForScroll = createAsyncThunk<
  TypeWorkers,
  Omit<IPaginate, 'total'>
>('workers/getWorkersForScroll', async (params) => {
  try {
    const { data } = await api.admin.workers.getWorkers(params);
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      handleServerErrors(err);
    }
    throw err;
  }
});

const createWorkers = createAsyncThunk<TypeWorkers, IDataCreateUser>(
  'workers/createWorkers',
  async (formData) => {
    try {
      const { data } = await api.admin.workers.createWorkers(formData);
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleServerErrors(err);
      }
      throw err;
    }
  }
);

const getUserId = createAsyncThunk(
  'workers/getWorkerId',
  async (id: string) => {
    try {
      const { data } = await api.admin.workers.getWorkerId(id);
      return data.data as Partial<IWorker>;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleServerErrors(err);
      }
      throw err;
    }
  }
);

const updateWorker = createAsyncThunk<
  IWorker,
  { formData: IWorkerUpdate; id: string }
>('workers/updateWorker', async (formData) => {
  try {
    const { data } = await api.admin.workers.updateWorker(formData);
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      handleServerErrors(err);
    }
    throw err;
  }
});

const deleteWorker = createAsyncThunk(
  'workers/deleteWorker',
  async (id: string) => {
    try {
      const { data } = await api.admin.workers.deleteWorker(id);
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleServerErrors(err);
      }
      throw err;
    }
  }
);

const restoreWorker = createAsyncThunk(
  'workers/restoreWorker',
  async (id: string) => {
    try {
      const { data } = await api.admin.workers.restoreWorker(id);
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleServerErrors(err);
      }
      throw err;
    }
  }
);

export const thunks = {
  getWorkers,
  getWorkersForScroll,
  createWorkers,
  getUserId,
  updateWorker,
  deleteWorker,
  restoreWorker,
};
