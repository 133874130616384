import { createSlice } from '@reduxjs/toolkit';

import { StatusEnum } from 'utils/const';
import { IPaginate } from 'types/common';
import { thunks } from './thunks';
import { selectors } from './selectors';
import { IBalance, IOperation } from './types';

interface TiptopsState {
  balance: IBalance[];
  sent: IOperation[];
  received: IOperation[];
  pagination: IPaginate;
  getBalanceStatus: StatusEnum;
  getTransactionsStatus: StatusEnum;
}

const initialState: TiptopsState = {
  balance: [],
  sent: [],
  received: [],
  pagination: {},
  getBalanceStatus: StatusEnum.IDLE,
  getTransactionsStatus: StatusEnum.IDLE,
};

const slice = createSlice({
  name: 'tiptops',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getBalance.pending, (state) => {
        state.getBalanceStatus = StatusEnum.PENDING;
      })
      .addCase(thunks.getBalance.fulfilled, (state, { payload }) => {
        state.getBalanceStatus = StatusEnum.SUCCESS;
        state.balance = payload;
      })
      .addCase(thunks.getBalance.rejected, (state) => {
        state.getBalanceStatus = StatusEnum.FAIL;
      })
      .addCase(thunks.getTransactions.pending, (state) => {
        state.getTransactionsStatus = StatusEnum.PENDING;
      })
      .addCase(thunks.getTransactions.fulfilled, (state, { payload, meta }) => {
        const { current_page, per_page, total } = payload.meta;

        state.pagination = {
          total,
          perPage: per_page,
          current: current_page,
        };

        if (Object.hasOwn(meta.arg, 'received')) {
          state.received = payload.data;
        } else {
          state.sent = payload.data;
        }

        state.getTransactionsStatus = StatusEnum.SUCCESS;
      })
      .addCase(thunks.getTransactions.rejected, (state) => {
        state.getTransactionsStatus = StatusEnum.FAIL;
      });
  },
});

const tiptops = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { tiptops };
export default slice.reducer;
