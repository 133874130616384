import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';
import { IPaginate } from 'types/common';

const transaction = {
  getTransactions(params: Omit<IPaginate, 'total'>) {
    return http.get(WEB_API_ROUTES.admin.transaction.getTransactions, {
      params,
    });
  },

  searchUsers(search: string) {
    return http.get(WEB_API_ROUTES.admin.transaction.searchUsers, {
      params: { search },
    });
  },
};

export { transaction };
