import { RLoader, RLoaderProps } from 'components/common/RLoader';
import { DefaultProps } from 'types/common';
import { Overlay } from './styled';

interface RLoadingOverlayProps
  extends DefaultProps,
    Pick<RLoaderProps, 'size'> {
  position?: 'absolute' | 'fixed';
  isVisible: boolean;
  bgColor?: string;
}

export function RLoadingOverlay({
  position = 'fixed',
  isVisible,
  bgColor,
  size,
}: RLoadingOverlayProps) {
  return (
    <Overlay
      position={position}
      isVisible={isVisible}
      bgColor={bgColor}
    >
      {isVisible ? <RLoader size={size} /> : null}
    </Overlay>
  );
}
