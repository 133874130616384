import axios, { AxiosResponse } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';
import { ILogin, ILoginData, IRejectLoginData } from './types';

const login = createAsyncThunk<
  ILoginData,
  ILogin,
  { rejectValue: IRejectLoginData }
>('auth/login', async (fd, { rejectWithValue }) => {
  try {
    const { data } = await api.auth.login(fd);
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      const { status, data } = err.response as AxiosResponse;

      if (status !== 404) {
        handleServerErrors(err);
      } else {
        return rejectWithValue(data);
      }
    }
    throw err;
  }
});

const logout = createAsyncThunk('auth/logout', async () => {
  try {
    const { data } = await api.auth.logout();
    return data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      handleServerErrors(err);
    }
    throw err;
  }
});

const thunks = {
  login,
  logout,
};

export { thunks };
