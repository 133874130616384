import { ReactNode } from 'react';
import { ReactComponent as AccountingIcon } from 'assets/img/icons/department/accounting.svg';
import { ReactComponent as AndroidIcon } from 'assets/img/icons/department/android.svg';
import { ReactComponent as BackendIcon } from 'assets/img/icons/department/backend.svg';
import { ReactComponent as DesignIcon } from 'assets/img/icons/department/design.svg';
import { ReactComponent as FrontendIcon } from 'assets/img/icons/department/frontend.svg';
import { ReactComponent as HrIcon } from 'assets/img/icons/department/hr.svg';
import { ReactComponent as IosIcon } from 'assets/img/icons/department/ios.svg';
import { ReactComponent as PmIcon } from 'assets/img/icons/department/pm.svg';
import { ReactComponent as QaIcon } from 'assets/img/icons/department/qa.svg';
import { theme } from 'components/Theme';

enum StatusEnum {
  IDLE = 'idle',
  PENDING = 'pending',
  SUCCESS = 'success',
  FAIL = 'fail',
}

enum ThunkStatusEnum {
  PENDING = 'pending',
  FULFILLED = 'fulfilled',
  REJECTED = 'rejected',
}

const projectsIds = {
  ENGLISH: 52,
  SICK_LEAVE: 53,
  VACATION: 54,
  UNPAID_VACATION: 55,
  ANOTHER: 'another',
} as const;

const inputVariant = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
} as const;

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
] as const;

interface IDepart {
  [key: string]: { icon: ReactNode; color: string; txtColor: string };
}

const DepartmentEnumIcon: IDepart = {
  Accounting: {
    icon: <AccountingIcon />,
    color: theme.varden,
    txtColor: theme.pumpkin,
  },
  Android: {
    icon: <AndroidIcon />,
    color: theme.patternsBlue,
    txtColor: theme.cornflowerBlue,
  },
  Backend: {
    icon: <BackendIcon />,
    color: theme.snowyMint,
    txtColor: theme.forestGreen,
  },
  Design: {
    icon: <DesignIcon />,
    color: theme.frostedMint,
    txtColor: theme.mountainMeadow,
  },
  Frontend: {
    icon: <FrontendIcon />,
    color: theme.snowyMint,
    txtColor: theme.forestGreen,
  },
  'Human Resource': {
    icon: <HrIcon />,
    color: theme.australianMint,
    txtColor: theme.forestGreen,
  },
  iOS: {
    icon: <IosIcon />,
    color: theme.patternsBlue,
    txtColor: theme.cornflowerBlue,
  },
  'PM & DM': {
    icon: <PmIcon />,
    color: theme.whitePointer,
    txtColor: theme.purpleHeart,
  },
  QA: {
    icon: <QaIcon />,
    color: theme.frostedMint,
    txtColor: theme.mountainMeadow,
  },
  default: {
    icon: <></>,
    color: 'transparent',
    txtColor: '',
  },
};

const dataFilterDepartment = [
  'Accounting',
  'Android',
  'Backend',
  'Design',
  'Frontend',
  'Human Resource',
  'iOS',
  'PM & DM',
  'QA',
];

export {
  StatusEnum,
  months,
  ThunkStatusEnum,
  projectsIds,
  inputVariant,
  DepartmentEnumIcon,
  dataFilterDepartment,
};
