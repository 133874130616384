import { RootState } from 'store';

export const selectors = {
  transactions: (state: RootState) => state.adminTransaction.transactions,
  statusSearchUser: (state: RootState) =>
    state.adminTransaction.statusSearchUser,
  userSearch: (state: RootState) => state.adminTransaction.userSearch,
  meta: (state: RootState) => state.adminTransaction.metaTransaction,
  statusTransactions: (state: RootState) =>
    state.adminTransaction.statusTransactions,
};
