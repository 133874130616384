import { format, parseISO } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { createSlice } from '@reduxjs/toolkit';

import { StatusEnum } from 'utils/const';
import { IReport } from 'store/user/reportHistory/types';
import { monthWithYearFormat, sentReportFormat } from 'utils/formats';
import { thunks } from './thunks';
import { selectors } from './selectors';

interface CreateReportState {
  list: IReport[] | [];
  fetchingReportsListStatus: StatusEnum;
  exportingReportStatus: StatusEnum;
}

const initialState: CreateReportState = {
  list: [],
  fetchingReportsListStatus: StatusEnum.IDLE,
  exportingReportStatus: StatusEnum.IDLE,
};

const slice = createSlice({
  name: 'reportHistory',
  initialState,
  reducers: {
    RESET_STATE: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getReportHistory.pending, (state) => {
        state.fetchingReportsListStatus = StatusEnum.PENDING;
      })
      .addCase(thunks.getReportHistory.fulfilled, (state, { payload }) => {
        state.list = payload.map((item) => {
          item.periodOriginal = format(
            parseISO(item.period),
            monthWithYearFormat,
            { locale: enUS }
          );
          item.sentAt = format(parseISO(item.sentAt), sentReportFormat);
          item.period = format(parseISO(item.period), monthWithYearFormat);

          return item;
        });

        state.fetchingReportsListStatus = StatusEnum.SUCCESS;
      })
      .addCase(thunks.getReportHistory.rejected, (state) => {
        state.fetchingReportsListStatus = StatusEnum.FAIL;
      });
  },
});

const reportHistory = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { reportHistory };
export default slice.reducer;
