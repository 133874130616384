import { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';

export const theme = {
  white: '#ffffff',
  transparentWhite: 'rgba(255, 255, 255, 0.07)',
  transparentWhite2: 'rgba(255, 255, 255, 0.05)',
  transparentWhite25: 'rgba(235, 238, 244, 0.25)',
  transparentWhite3: 'rgba(255, 255, 255, 0.1)',
  transparentWhite4: 'rgba(255, 255, 255, 0.02)',
  transparentWhite5: 'rgba(139, 141, 161, 0.10)',
  transparentGray05: 'rgba(208, 209, 214, 0.50)',
  lightBlack: 'rgba(0, 0, 0, 0.4)',
  transparentBlack: 'rgba(0, 0, 0, 0.25)',
  oil: '#1E1E21',
  oilLight: 'rgba(30, 30, 33, 0.5)',
  steelGrey: '#787F87',
  greyChateau: '#A4A6A8',
  nebula: '#D0D5DD',
  greyGoose: '#D0D1D6',
  greyGooseLight: 'rgba(208, 209, 214, 0.5)',
  linkWater: '#E3E6E9',
  dawnPink: '#EBEBEB',
  platinum: '#E8E8EB',
  nobel: '#B2B2B2',
  dustyGrey: '#9A9A9A',
  whiteLilac: '#F7F7F9',
  satinLinen: '#E4E4E4',
  titanWhite: '#EBEEF4',
  geyser: '#D8DEEA',
  frenchGrey: '#BEBEBE',
  ghostWhite: '#F8F8F9',
  amethystSmoke: '#9A9AAA',
  catskillWhite: '#F0F4FA',
  greySuit: '#BDBFCE',
  ghost: '#C8CADC',
  carouselPink: '#E8E8ED',
  gunsmoke: '#7E868F',
  zeusLight: 'rgba(30, 30, 33, 0.35)',
  water: '#EDF2FF',
  clearBlue: '#007AFF',
  blueLotus: '#525FFD',
  dodgerBlue: '#4579FF',
  dodgerBlue20: 'rgba(69,121,255, 0.2)',
  dodgerBlueTransparent: 'rgba(69, 121, 255, 0.1)',
  dodgerBlueLightest: 'rgba(69, 121, 255, 0.2)',
  dodgerBlueLightest2: 'rgba(69, 121, 255, 0.25)',
  dodgerBlueLight: 'rgba(69, 121, 255, 0.35)',
  dodgerBlueLight02: 'rgba(69, 121, 255, 0.20)',
  royalBlue: '#3A68DF',
  ceruleanBlue: '#1E4BBF',
  downriver: 'rgba(9, 29, 82, 0.2)',
  downriverDark: 'rgba(11, 45, 135, 0.25)',
  red: '#EF3636',
  lightRed: '#FD5652',
  tomato: '#FF6745',
  sunsetOrange: '#FF6645',
  bittersweet: '#F96767',
  night: '#0C0C0C',
  iron: '#D9D9D9',
  lightGrey: '#DBDBDB',
  cyanBlue: '#979DA5',
  brightGrey: '#3E444A',
  regentGrey: 'rgba(139, 141, 161, 0.1)',
  metallicSilver: '#C3C5C7',
  smokyBlack: '#101010',
  thunder: '#2C2C30',
  whiteSmoke: '#F5F5F5',
  mercury: '#E7E6E6',
  lavenderMist: '#E4E7EC',
  raven: '#72798C',
  coolGrey: '#979CA4',
  manatee: '#8B8DA1',
  regentGreyFull: '#8C929B',
  spanishGray: '#959597',
  shipGrey: 'rgba(57, 57, 67, 0.85)',
  whitePointer: '#FCDBFF',
  purpleHeart: '#BB2CD2',
  purple: '#CF45FF',
  varden: '#FFF7DB',
  pumpkin: '#FF7918',
  patternsBlue: '#E0F4FF',
  cornflowerBlue: '#4875F3',
  ultramarineBlue: '#456BF5',
  snowyMint: '#D3FFCC',
  forestGreen: '#46A327',
  limeGreen: '#4EA823',
  australianMint: '#F6FFC0',
  frostedMint: '#DAFFFB',
  mountainMeadow: '#14B578',
  lightGreen: '#D3FFCC',
  mainBoxShadow: '0px 14px 20px rgba(131, 141, 167, 0.05)',
  secondaryBoxShadow: '0px 12px 22px 4px rgba(0, 0, 0, 0.08)',
  thirdlyBoxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
  filterTableShadow: '0px 12px 16px 2px rgba(64, 73, 104, 0.08)',
  popupBoxShadow:
    '0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04)',
  bannerBoxShadow: 'inset 0px -4px 20px rgba(0, 0, 0, 0.1)',
};

interface ThemeProps {
  children: ReactNode;
}

function Theme({ children }: ThemeProps) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export type CustomTheme = typeof theme;
export default Theme;
