const size = {
  xxs: '360px',
  xs: '390px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1024px',
  xxl: '1200px',
};

const device = {
  xxs: `max-width: ${size.xxs}`,
  xs: `max-width: ${size.xs}`,
  sm: `max-width: ${size.sm}`,
  md: `max-width: ${size.md}`,
  lg: `max-width: ${size.lg}`,
  xl: `max-width: ${size.xl}`,
  xxl: `max-width: ${size.xxl}`,
};

const mediaQuery = {
  xxs: `@media (max-width: ${size.xxs})`,
  xs: `@media (max-width: ${size.xs})`,
  sm: `@media (max-width: ${size.sm})`,
  md: `@media (max-width: ${size.md})`,
  lg: `@media (max-width: ${size.lg})`,
  xl: `@media (max-width: ${size.xl})`,
  xxl: `@media (max-width: ${size.xxl})`,
};

export { device, mediaQuery };
