import { RootState } from 'store';

export const selectors = {
  data: (state: RootState) => state.reportDetails.data,
  projects: (state: RootState) => state.reportDetails.projects,
  otherProjects: (state: RootState) => state.reportDetails.otherProjects,
  workingHours: (state: RootState) => state.reportDetails.workingHours,
  totalWorkedInMinutes: (state: RootState) =>
    state.reportDetails.totalWorkedInMinutes,
  fetchingDetailsStatus: (state: RootState) =>
    state.reportDetails.fetchingDetailsStatus,
  fetchingWorkingHoursStatus: (state: RootState) =>
    state.reportDetails.fetchingWorkingHoursStatus,
};
