import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';
import { IGetWorkingHoursParams } from 'store/user/createReport/types';

const additional = {
  getProjects() {
    return http.get(WEB_API_ROUTES.additional.project);
  },

  getWorkingHours(params: IGetWorkingHoursParams) {
    return http.get(WEB_API_ROUTES.additional.getWorkingHours, {
      params,
    });
  },

  exportReport(reportId: number) {
    return http.get(
      WEB_API_ROUTES.additional.exportReport.replace(
        '{report}',
        reportId.toString()
      ),
      { responseType: 'blob' }
    );
  },
};

export { additional };
