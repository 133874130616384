import { RootState } from 'store';

export const selectors = {
  product: (state: RootState) => state.shopProduct.product,
  orders: (state: RootState) => state.shopProduct.orders,
  statusGetWorker: (state: RootState) => state.shopProduct.createProductStatus,
  editProductStatus: (state: RootState) => state.shopProduct.editProductStatus,
  getProductStatus: (state: RootState) => state.shopProduct.getProductStatus,
  deleteProductStatus: (state: RootState) =>
    state.shopProduct.deleteProductStatus,
  getOrdersStatus: (state: RootState) => state.shopProduct.getOrdersStatus,
};
