import styled, { css, keyframes } from 'styled-components';

interface SpinnerProps {
  size: {
    width: number;
    height: number;
  };
}

const spinner = keyframes`
  0% {
    transform: rotate(0deg);
  }
  
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div<SpinnerProps>(
  ({ theme, size, css: styles }) => css`
    width: ${size.width}px;
    height: ${size.height}px;
    margin: auto;
    border: ${size.width / 21}px solid ${theme.white};
    border-top: ${size.width / 21}px solid ${theme.frenchGrey};
    border-radius: 50%;
    animation: ${spinner} 1.5s linear infinite;

    ${styles};
  `
);

export { Spinner };
