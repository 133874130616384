import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from 'utils/const';
import { thunks } from './thunks';
import { selectors } from './selectors';

interface IAdditionalStore {
  monthlyTopsAccrual: number;
  finishOrderStatus: StatusEnum;
  finishAllOrderStatus: StatusEnum;
  sendTokenStatus: StatusEnum;
  getMonthlyTipTopsStatus: StatusEnum;
  setMonthlyTipTopsStatus: StatusEnum;
}

const statuses = {
  finishOrderStatus: StatusEnum.IDLE,
  finishAllOrderStatus: StatusEnum.IDLE,
  sendTokenStatus: StatusEnum.IDLE,
  getMonthlyTipTopsStatus: StatusEnum.IDLE,
  setMonthlyTipTopsStatus: StatusEnum.IDLE,
};

const initialState: IAdditionalStore = {
  monthlyTopsAccrual: 0,
  ...statuses,
};

const slice = createSlice({
  name: 'adminAdditional',
  initialState,
  reducers: {
    RESET_STATUS: (
      state,
      { payload }: PayloadAction<keyof typeof statuses>
    ) => {
      state[payload] = StatusEnum.IDLE;
    },

    SET_MONTHLY_TIP_TOPS: (state, { payload }: PayloadAction<number>) => {
      state.monthlyTopsAccrual = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.finishOrder.pending, (state) => {
        state.finishOrderStatus = StatusEnum.PENDING;
      })
      .addCase(thunks.finishOrder.fulfilled, (state) => {
        state.finishOrderStatus = StatusEnum.SUCCESS;
      })
      .addCase(thunks.finishOrder.rejected, (state) => {
        state.finishOrderStatus = StatusEnum.FAIL;
      })

      .addCase(thunks.sendTokens.pending, (state) => {
        state.sendTokenStatus = StatusEnum.PENDING;
      })
      .addCase(thunks.sendTokens.fulfilled, (state) => {
        state.sendTokenStatus = StatusEnum.SUCCESS;
      })
      .addCase(thunks.sendTokens.rejected, (state) => {
        state.sendTokenStatus = StatusEnum.FAIL;
      })

      .addCase(thunks.finishAllOrders.pending, (state) => {
        state.finishAllOrderStatus = StatusEnum.PENDING;
      })
      .addCase(thunks.finishAllOrders.fulfilled, (state) => {
        state.finishAllOrderStatus = StatusEnum.SUCCESS;
      })
      .addCase(thunks.finishAllOrders.rejected, (state) => {
        state.finishAllOrderStatus = StatusEnum.FAIL;
      })

      .addCase(thunks.finishAllOrderForProduct.pending, (state) => {
        state.finishAllOrderStatus = StatusEnum.PENDING;
      })
      .addCase(thunks.finishAllOrderForProduct.fulfilled, (state) => {
        state.finishAllOrderStatus = StatusEnum.SUCCESS;
      })
      .addCase(thunks.finishAllOrderForProduct.rejected, (state) => {
        state.finishAllOrderStatus = StatusEnum.FAIL;
      })

      .addCase(thunks.getMonthlyTipTops.pending, (state) => {
        state.getMonthlyTipTopsStatus = StatusEnum.PENDING;
      })
      .addCase(thunks.getMonthlyTipTops.fulfilled, (state, { payload }) => {
        state.monthlyTopsAccrual = payload;
        state.getMonthlyTipTopsStatus = StatusEnum.SUCCESS;
      })
      .addCase(thunks.getMonthlyTipTops.rejected, (state) => {
        state.getMonthlyTipTopsStatus = StatusEnum.FAIL;
      })

      .addCase(thunks.setMonthlyTipTops.pending, (state) => {
        state.setMonthlyTipTopsStatus = StatusEnum.PENDING;
      })
      .addCase(thunks.setMonthlyTipTops.fulfilled, (state) => {
        state.setMonthlyTipTopsStatus = StatusEnum.SUCCESS;
      })
      .addCase(thunks.setMonthlyTipTops.rejected, (state) => {
        state.setMonthlyTipTopsStatus = StatusEnum.FAIL;
      });
  },
});

const adminAdditional = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { adminAdditional };
export default slice.reducer;
