import { AxiosError, AxiosResponse } from 'axios';

import { alert } from 'utils/alert';

export const handleServerErrors = (error: AxiosError) => {
  const { data, status, statusText } = error.response as AxiosResponse;

  console.log('%c => Error ', 'background: red; color: #000', error.response);
  if (data && data.errors) {
    Object.values(data.errors).forEach((errorMsg) => {
      if (Array.isArray(errorMsg)) {
        alert({ message: errorMsg[0], type: 'error' });
      }
    });
  } else if (data && data.message) {
    alert({ message: data.message, type: 'error' });
  } else {
    alert({ message: `Error ${status}: ${statusText}`, type: 'error' });
  }
};
