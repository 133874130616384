import { IWallet } from 'store/admin/shop/types';

export const walletModel = (wallets: IWallet[]) => {
  const personal = wallets.find(({ isPersonal }) => !!isPersonal) as IWallet;
  const send = wallets.find(({ isPersonal }) => !isPersonal) as IWallet;

  return {
    personal,
    send,
  };
};
