import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from 'utils/const';
import { thunks } from './thunks';
import { selectors } from './selectors';
import { IOrder, IProduct } from './types';

interface IProductState {
  product: IProduct | null;
  orders: IOrder[];
  createProductStatus: StatusEnum;
  getProductStatus: StatusEnum;
  editProductStatus: StatusEnum;
  deleteProductStatus: StatusEnum;
  getOrdersStatus: StatusEnum;
}

const statuses = {
  createProductStatus: StatusEnum.IDLE,
  getProductStatus: StatusEnum.IDLE,
  editProductStatus: StatusEnum.IDLE,
  deleteProductStatus: StatusEnum.IDLE,
  getOrdersStatus: StatusEnum.IDLE,
};

const initialState: IProductState = {
  product: null,
  orders: [],
  ...statuses,
};

const slice = createSlice({
  name: 'shop/product',
  initialState,
  reducers: {
    RESET_STATE: (state) => {
      Object.assign(state, initialState);
    },

    SET_STATUS: (state, { payload }) => {
      state.createProductStatus = payload;
      state.editProductStatus = payload;
      state.deleteProductStatus = payload;
    },

    CLEAR_PRODUCT: (state) => {
      state.product = null;
    },

    RESET_STATUS: (
      state,
      { payload }: PayloadAction<keyof typeof statuses>
    ) => {
      state[payload] = StatusEnum.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.createProduct.pending, (state) => {
        state.createProductStatus = StatusEnum.PENDING;
      })
      .addCase(thunks.createProduct.fulfilled, (state) => {
        state.createProductStatus = StatusEnum.SUCCESS;
      })
      .addCase(thunks.createProduct.rejected, (state) => {
        state.createProductStatus = StatusEnum.FAIL;
      })

      .addCase(thunks.getProduct.pending, (state) => {
        state.getProductStatus = StatusEnum.PENDING;
      })
      .addCase(thunks.getProduct.fulfilled, (state, { payload }) => {
        state.product = payload;
        state.getProductStatus = StatusEnum.SUCCESS;
      })
      .addCase(thunks.getProduct.rejected, (state) => {
        state.getProductStatus = StatusEnum.FAIL;
      })

      .addCase(thunks.editProduct.pending, (state) => {
        state.editProductStatus = StatusEnum.PENDING;
      })
      .addCase(thunks.editProduct.fulfilled, (state) => {
        state.editProductStatus = StatusEnum.SUCCESS;
      })
      .addCase(thunks.editProduct.rejected, (state) => {
        state.editProductStatus = StatusEnum.FAIL;
      })

      .addCase(thunks.deleteProduct.pending, (state) => {
        state.deleteProductStatus = StatusEnum.PENDING;
      })
      .addCase(thunks.deleteProduct.fulfilled, (state) => {
        state.deleteProductStatus = StatusEnum.SUCCESS;
      })
      .addCase(thunks.deleteProduct.rejected, (state) => {
        state.deleteProductStatus = StatusEnum.FAIL;
      })

      .addCase(thunks.getOrders.pending, (state) => {
        state.getOrdersStatus = StatusEnum.PENDING;
      })
      .addCase(thunks.getOrders.fulfilled, (state, { payload }) => {
        state.orders = payload;
        state.getOrdersStatus = StatusEnum.SUCCESS;
      })
      .addCase(thunks.getOrders.rejected, (state) => {
        state.getOrdersStatus = StatusEnum.FAIL;
      });
  },
});

const shopProduct = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { shopProduct };
export default slice.reducer;
