import { DefaultProps } from 'types/common';
import { Spinner } from './styled';

const sizeConst = {
  xl: {
    width: 168,
    height: 168,
  },
  xs: {
    width: 80,
    height: 80,
  },
  xxs: {
    width: 25,
    height: 25,
  },
};

export interface RLoaderProps extends DefaultProps {
  size?: keyof typeof sizeConst;
}

function RLoader({ size = 'xl', css }: RLoaderProps) {
  return (
    <Spinner
      css={css}
      size={sizeConst[size]}
    />
  );
}

export { RLoader };
