import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';
import { IPaginate } from 'types/common';
import { IMakeOrder, IProduct, ResIProduct } from './types';

const getProduct = createAsyncThunk(
  'topShop/getProduct',
  async (productId: string) => {
    try {
      const { data } = await api.user.topShopModule.getProduct(productId);
      return data.data as IProduct;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleServerErrors(err);
      }
      throw err;
    }
  }
);

const makeOrder = createAsyncThunk(
  'topShop/makeOrder',
  async (params: IMakeOrder) => {
    try {
      const { data } = await api.user.topShopModule.makeOrder(params);
      return data.data as IProduct;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
);

const getProducts = createAsyncThunk(
  'topShop/getProducts',
  async (params: IPaginate) => {
    try {
      const { data } = await api.user.topShopModule.getProducts(params);
      return data as ResIProduct;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleServerErrors(err);
      }
      throw err;
    }
  }
);

export const thunks = { getProduct, makeOrder, getProducts };
