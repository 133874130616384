import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import store from 'store';
import App from 'components/App';
import Theme from 'components/Theme';
import { RebootStyles } from 'components/Theme/GlobalStyled/global/reboot';
import { GlobalStyles } from 'components/Theme/GlobalStyled/global';
import { ComponentsStyle } from 'components/Theme/GlobalStyled/global/components';
import Router from 'router';

const persist = persistStore(store);

ReactDOM.createRoot(document.getElementById(`root`) as HTMLElement).render(
  <>
    <Provider store={store}>
      <PersistGate
        loading={null}
        persistor={persist}
      >
        <Theme>
          <RebootStyles />
          <GlobalStyles />
          <ComponentsStyle />

          <Router>
            <App />
          </Router>
        </Theme>
      </PersistGate>
    </Provider>
  </>
);
