import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';
import { IProduct, IOrder } from './types';

const getProduct = createAsyncThunk(
  'shop/product/getProduct',
  async (id: string) => {
    try {
      const { data } = await api.admin.shop.getProduct(id);
      return data?.data as IProduct;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleServerErrors(err);
      }
      throw err;
    }
  }
);

const createProduct = createAsyncThunk<any, FormData>(
  'shop/product/createProduct',
  async (formData) => {
    try {
      const { data } = await api.admin.shop.createProduct(formData);
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleServerErrors(err);
      }
      throw err;
    }
  }
);

const editProduct = createAsyncThunk(
  'shop/product/editProduct',
  async (formData: { data: FormData; id: string }) => {
    try {
      const { data } = await api.admin.shop.editProduct(formData);
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleServerErrors(err);
      }
      throw err;
    }
  }
);

const deleteProduct = createAsyncThunk(
  'shop/product/deleteProduct',
  async (id: string) => {
    try {
      const { data } = await api.admin.shop.deleteProduct(id);
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleServerErrors(err);
      }
      throw err;
    }
  }
);

const getOrders = createAsyncThunk(
  'shop/product/getOrders',
  async (productId: string) => {
    try {
      const { data } = await api.admin.shop.getOrdersForProduct(productId);
      return data.data as IOrder[];
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleServerErrors(err);
      }
      throw err;
    }
  }
);

export const thunks = {
  createProduct,
  getProduct,
  editProduct,
  deleteProduct,
  getOrders,
};
