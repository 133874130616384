import styled from 'styled-components';

import { Flex } from 'components/Theme/GlobalStyled';

const Wrap = styled.div`
  ${Flex({ justify: 'center' })};
  height: 100%;
  font-weight: 700;
  font-size: 48px;
  text-transform: uppercase;
`;

export { Wrap };
