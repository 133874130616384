import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';
import { IPaginate } from 'types/common';
import { IDataCreateUser } from 'components/Admin/WorkersPage/ModalForm/config';
import { IWorker } from 'store/admin/workers/types';

const workers = {
  getWorkers(params?: Omit<IPaginate, 'total' | 'current'>) {
    return http.get(WEB_API_ROUTES.admin.workers.getWorkers, { params });
  },

  createWorkers(data: IDataCreateUser) {
    return http.post(WEB_API_ROUTES.admin.workers.getWorkers, data);
  },

  getWorkerId(id: string) {
    return http.get(
      WEB_API_ROUTES.admin.workers.createWorkers.replace('{user}', id)
    );
  },

  updateWorker(data: {
    formData: Omit<Partial<IWorker>, 'wallets'>;
    id: string;
  }) {
    const { id, formData } = data;

    return http.put(
      WEB_API_ROUTES.admin.workers.updateWorker.replace('{user}', id),
      formData
    );
  },

  deleteWorker(id: string) {
    return http.delete(
      WEB_API_ROUTES.admin.workers.updateWorker.replace('{user}', id)
    );
  },

  restoreWorker(id: string) {
    return http.post(
      WEB_API_ROUTES.admin.workers.restoreWorker.replace('{user}', id)
    );
  },
};

export { workers };
