import axios from 'axios';

import store from 'store';
import { auth } from 'store/auth';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api`,
  timeout: 30000,
});

// unauthorized interceptor
instance.interceptors.response.use(
  (res) => res,
  (err) => {
    const { status } = err.response;

    if (status === 401) {
      store.dispatch(auth.actions.UNAUTHORIZED());
    }

    return Promise.reject(err);
  }
);

instance.interceptors.request.use((config) => {
  const { auth } = store.getState();

  if (auth.token && config.headers) {
    config.headers.Authorization = `Bearer ${auth.token}`;
  }

  return config;
});

export default instance;
