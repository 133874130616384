import { createSlice } from '@reduxjs/toolkit';

import { ISelect } from 'types/common';
import { StatusEnum } from 'utils/const';
import { objToSelectFormat } from 'utils/prepare';
import { selectors } from './selectors';
import { thunks } from './thunks';

interface AdditionalState {
  defaultProjects: ISelect[];
  fetchingProjectsStatus: StatusEnum;
  exportReportStatus: StatusEnum;
}

const initialState: AdditionalState = {
  defaultProjects: [],
  fetchingProjectsStatus: StatusEnum.IDLE,
  exportReportStatus: StatusEnum.IDLE,
};

const slice = createSlice({
  name: 'additional',
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(thunks.getProjects.pending, (state) => {
        state.fetchingProjectsStatus = StatusEnum.PENDING;
      })
      .addCase(thunks.getProjects.fulfilled, (state, { payload }) => {
        if (payload) {
          state.defaultProjects = payload.defaultProjects.map(({ id, name }) =>
            objToSelectFormat(name, id)
          );
        }
        state.fetchingProjectsStatus = StatusEnum.SUCCESS;
      })
      .addCase(thunks.getProjects.rejected, (state) => {
        state.fetchingProjectsStatus = StatusEnum.FAIL;
      })

      .addCase(thunks.exportReport.pending, (state) => {
        state.exportReportStatus = StatusEnum.PENDING;
      })
      .addCase(thunks.exportReport.fulfilled, (state) => {
        state.exportReportStatus = StatusEnum.SUCCESS;
      })
      .addCase(thunks.exportReport.rejected, (state) => {
        state.exportReportStatus = StatusEnum.FAIL;
      }),
});

export const additional = {
  actions: slice.actions,
  selectors,
  thunks,
};
export default slice.reducer;
