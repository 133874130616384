import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from 'utils/const';
import { IPaginate } from 'types/common';
import { thunks } from './thunks';
import { selectors } from './selectors';
import { IProduct } from './types';

interface TiptopsState {
  product: null | IProduct;
  dataProduct: IProduct[];
  metaProductList: IPaginate;
  statusProduct: StatusEnum;
  getProductStatus: StatusEnum;
  makeOrderStatus: StatusEnum;
}

const statuses = {
  getProductStatus: StatusEnum.IDLE,
  makeOrderStatus: StatusEnum.IDLE,
  statusProduct: StatusEnum.IDLE,
};

const initialState: TiptopsState = {
  product: null,
  dataProduct: [],
  metaProductList: {},
  ...statuses,
};

const slice = createSlice({
  name: 'topShop',
  initialState,
  reducers: {
    RESET_STATUS: (
      state,
      { payload }: PayloadAction<keyof typeof statuses>
    ) => {
      state[payload] = StatusEnum.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getProduct.pending, (state) => {
        state.getProductStatus = StatusEnum.PENDING;
      })
      .addCase(thunks.getProduct.fulfilled, (state, { payload }) => {
        state.product = payload;
        state.getProductStatus = StatusEnum.SUCCESS;
      })
      .addCase(thunks.getProduct.rejected, (state) => {
        state.getProductStatus = StatusEnum.FAIL;
      })

      .addCase(thunks.makeOrder.pending, (state) => {
        state.makeOrderStatus = StatusEnum.PENDING;
      })
      .addCase(thunks.makeOrder.fulfilled, (state) => {
        state.makeOrderStatus = StatusEnum.SUCCESS;
      })
      .addCase(thunks.makeOrder.rejected, (state) => {
        state.makeOrderStatus = StatusEnum.FAIL;
      })

      .addCase(thunks.getProducts.pending, (state) => {
        state.statusProduct = StatusEnum.PENDING;
      })
      .addCase(thunks.getProducts.fulfilled, (state, { payload }) => {
        const { current_page, per_page, total } = payload.meta;

        state.metaProductList = {
          perPage: per_page,
          current: current_page,
          total,
        };

        state.dataProduct = payload.data;
        state.statusProduct = StatusEnum.SUCCESS;
      })
      .addCase(thunks.getProducts.rejected, (state) => {
        state.statusProduct = StatusEnum.FAIL;
      });
  },
});

const topShop = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { topShop };
export default slice.reducer;
