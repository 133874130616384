import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';
import {
  IEditReportParam,
  IImportFromTimeDoctorParams,
  ISaveReportParam,
} from 'store/user/createReport/types';

const createReport = {
  getLatestReport() {
    return http.get(WEB_API_ROUTES.user.createReport.latestReport);
  },

  importFromTimeDoctor(params: IImportFromTimeDoctorParams) {
    return http.get(WEB_API_ROUTES.user.createReport.importFromTimeDoctor, {
      params,
    });
  },

  saveReport(fd: ISaveReportParam) {
    return http.post(WEB_API_ROUTES.user.createReport.report, fd);
  },

  sendReport(reportId: number) {
    return http.post(
      WEB_API_ROUTES.user.createReport.sendReport.replace(
        '{report}',
        reportId.toString()
      )
    );
  },

  editReport(reportId: number, formData: IEditReportParam['formData']) {
    return http.put(
      WEB_API_ROUTES.user.createReport.reportById.replace(
        '{report}',
        reportId.toString()
      ),
      formData
    );
  },

  deleteReport(reportId: number) {
    return http.delete(
      WEB_API_ROUTES.user.createReport.reportById.replace(
        '{report}',
        reportId.toString()
      )
    );
  },
};

export { createReport };
