import { RootState } from 'store';

const selectors = {
  token: (state: RootState) => state.auth.token,
  roles: (state: RootState) => state.auth.roles,
  user: (state: RootState) => state.auth.user,
  notFondUserError: (state: RootState) => state.auth.notFondUserError,
  loginStatus: (state: RootState) => state.auth.loginStatus,
  logoutStatus: (state: RootState) => state.auth.logoutStatus,
};

export { selectors };
