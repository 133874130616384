import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { api } from 'api';
import { handleServerErrors } from 'utils/serverErrors';
import { IPaginate } from 'types/common';
import { IOrders, TypeProduct } from './types';

const getProductList = createAsyncThunk(
  'shop/getProductList',
  async (params: IPaginate) => {
    try {
      const { data } = await api.admin.shop.getListProduct(params);
      return data as TypeProduct;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleServerErrors(err);
      }
      throw err;
    }
  }
);

const getOrdersList = createAsyncThunk(
  'shop/getOrdersList',
  async (params: IPaginate) => {
    try {
      const { data } = await api.admin.shop.getOrdersList(params);
      return data as IOrders;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        handleServerErrors(err);
      }
      throw err;
    }
  }
);

export const thunks = {
  getProductList,
  getOrdersList,
};
