import { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { setDefaultOptions } from 'date-fns';
import { uk } from 'date-fns/locale';

import { RLoadingOverlay } from 'components/common/RLoadingOverlay';
import NotFoundPage from 'pages/General/NotFound';

const UserLayout = lazy(() => import('layouts/UserLayout'));
const AuthLayout = lazy(() => import('layouts/AuthLayout'));
const AdminLayout = lazy(() => import('layouts/AdminLayout'));

setDefaultOptions({ locale: uk });

function App() {
  return (
    <Suspense fallback={<RLoadingOverlay isVisible />}>
      <Switch>
        <Route
          path="/admin"
          component={AdminLayout}
        />
        <Route
          path="/user"
          component={UserLayout}
        />
        <Route
          path="/"
          component={AuthLayout}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </Suspense>
  );
}

export default App;
