import storage from 'redux-persist/lib/storage';
import { Action, combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

// REDUCERS
import auth from 'store/auth';
import additional from 'store/additional';
import * as userReducers from 'store/user';
import * as adminReducers from 'store/admin';

const appReducer = combineReducers({
  auth,
  additional,
  ...userReducers,
  ...adminReducers,
});

type RootState = ReturnType<typeof appReducer>;
type AppDispatch = typeof store.dispatch;

const rootReducer = (state: RootState | undefined, action: Action) => {
  if (
    action.type === 'auth/logout/fulfilled' ||
    action.type === 'auth/UNAUTHORIZED'
  ) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const persistConfig = {
  key: 'reports',
  storage,
  blacklist: [],
  whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type { RootState, AppDispatch };
export default store;
