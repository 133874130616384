import { RootState } from 'store';

export const selectors = {
  listProduct: (state: RootState) => state.shop.listProduct,
  paginationProduct: (state: RootState) => state.shop.paginationProduct,
  getProductsStatus: (state: RootState) => state.shop.getProductsStatus,
  paginationOrders: (state: RootState) => state.shop.paginationOrders,
  ordersList: (state: RootState) => state.shop.ordersList,
  getOrdersListStatus: (state: RootState) => state.shop.getOrdersList,
};
