import { ReactNode } from 'react';
import { BrowserRouter } from 'react-router-dom';

interface RouterProps {
  children: ReactNode;
}

function Router({ children }: RouterProps) {
  return <BrowserRouter>{children}</BrowserRouter>;
}

export default Router;
