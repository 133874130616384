import { RootState } from 'store';

export const selectors = {
  monthlyTopsAccrual: (state: RootState) =>
    state.adminAdditional.monthlyTopsAccrual,
  sendTokenStatus: (state: RootState) => state.adminAdditional.sendTokenStatus,
  finishAllOrderStatus: (state: RootState) =>
    state.adminAdditional.finishAllOrderStatus,
  finishOrderStatus: (state: RootState) =>
    state.adminAdditional.finishOrderStatus,
  getMonthlyTipTopsStatus: (state: RootState) =>
    state.adminAdditional.getMonthlyTipTopsStatus,
  setMonthlyTipTopsStatus: (state: RootState) =>
    state.adminAdditional.setMonthlyTipTopsStatus,
};
