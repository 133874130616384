import styled from 'styled-components';

const Overlay = styled.div<{
  position: string;
  bgColor?: string;
  isVisible: boolean;
}>`
  position: ${({ position }) => position};
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${({ bgColor, theme }) => bgColor || theme.zeusLight};
  z-index: 502;
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  transition: opacity 0.2s ease;
`;

export { Overlay };
