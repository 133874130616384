import http from 'api/http';
import { WEB_API_ROUTES } from 'api/api-routes';
import { ILogin } from 'store/auth/types';

const auth = {
  login(fd: ILogin) {
    return http.post(WEB_API_ROUTES.auth.login, fd);
  },

  logout() {
    return http.post(WEB_API_ROUTES.auth.logout);
  },
};

export { auth };
