export const WEB_API_ROUTES = {
  auth: {
    login: '/auth/login',
    logout: '/auth/logout',
  },

  user: {
    createReport: {
      report: '/report',
      latestReport: '/report/latest',
      reportById: '/report/{report}',
      sendReport: '/report/{report}/send',
      importFromTimeDoctor: '/timedoctor-tasks',
    },

    reportHistory: {
      getReportHistory: '/report',
    },
    reportDetails: {
      getReportDetails: '/report/{report}',
    },

    tipTops: {
      getBalance: '/balance',
      getTransactions: '/balance/transactions',
    },

    topShop: {
      productById: '/product/{product}',
      makeOrder: '/product/{product}/order',
      getProducts: '/product',
    },
  },

  admin: {
    workers: {
      getWorkers: '/admin/user',
      createWorkers: '/admin/user/{user}',
      updateWorker: '/admin/user/{user}',
      restoreWorker: '/admin/user/{user}/restore',
    },
    shop: {
      createProduct: '/admin/product',
      editProduct: '/admin/product/{product}',
      deleteProduct: '/admin/product/{product}',
      getListProduct: '/admin/product',
      getProduct: '/admin/product/{product}',
      getOrdersList: '/admin/order',
      getOrderById: '/admin/user/{user}/order',
      getOrdersForProduct: '/admin/product/{product}/order',
    },

    additional: {
      sendTokens: '/admin/balance/replenish',
      finishOrder: '/admin/order/{order}',
      finishAllOrder: '/admin/user/{user}/order/complete',
      finishAllOrderForProduct: '/admin/product/{product}/order/complete',
      monthlyTipTops: '/admin/tip-tops/monthly',
    },

    transaction: {
      getTransactions: '/admin/balance/transactions',
      searchUsers: '/admin/user/search',
    },
  },

  additional: {
    project: '/project',
    exportReport: '/report/{report}/export',
    getWorkingHours: '/month-working-hours',
  },
};
